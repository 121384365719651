@import "../../../../../assets/styles/variables.scss";

.HeadingStyle{
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    font-family: $font-Instrument;
}
.headingSearchWrap{
    display: flex;
    justify-content: space-between;
}
.valueStyle{
    font-size: 14px;
    font-weight: 400;
    font-family: $font-Instrument;
    overflow: hidden;
    text-overflow: ellipsis;
}
.viewStyle{

}
.EditStyle{
    padding-left: 10px;
}
.viewEdit{
    display: flex;
    align-items: center;
}
.searchButton{
    display: flex;
    align-items: center;
    gap: 20px;
}
.dashboard_container {
  display: flex;
  min-height: 100vh;
  height: 100vh;
  overflow: hidden;
}

.main_content_container {
  width: 100%;
  min-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.dashboard_content_container {
  flex: 1;
  min-height: 130vh;
  width: calc(100vw - 288px);
}
.content {
  padding: 24px;
  width: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  // min-height: calc(100vh - 60px);
  // max-height: calc(100vh - 60px);
}

::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
  height: 2px;
}

::-webkit-scrollbar-thumb {
  background: #c0c4c9; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Roundness of the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb when hovered */
}

@media (min-width: 200px) and (max-width: 900px) {
  .dashboard_container {
    width: 100%;

    .dashboard_content_container {
      width: 100%;
      min-height: 0vh;
    }
  }
}

@import '../../../../../../assets/styles/variables.scss';

// .permission_selector {
//   display: flex;
//   margin-bottom: 0.5rem;
//   align-items: start;

//   .label {
//     flex: 0 0 20vw;
//     margin-right: 10px;

//     color: $dark;
//     font-weight: 600;
//     font-size: 0.85rem;
//   }

//   .selector {
//     display: flex;
//     align-items: center;
//     justify-content: space-evenly;
//     width: 100%;
//   }
// }

// .checkbox_container {
//   display: flex;
//   flex-direction: row-reverse;
//   align-items: center;
//   label {
//     margin-bottom: 0;
//   }
// }

.permission-table {
  width: 100%;
  border-collapse: collapse;

  tr{
    height: 50px;
    border-bottom: 1px solid #DEE3E7;
    th{
    min-width: 18vw;
    }
  }
}

.permission-table th,
.permission-table td {
  border: 1px solid #DEE3E7;
  padding: 8px 0 8px 20px;
  text-align: left;
  border-left: 0;
  border-right: 0;
}

.permission-table th {
  background-color: #F1F3F5;
  color: #555F6D;
  font-weight: 500;
  height: 50px;
  border: 0;
  min-width: 100px;
}

.checkbox-input{
  max-width: 20px;
}

.permission_selector {
  & .label {
    text-align: left;
  }

  & .checkbox_container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.role_checked{
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid $side_grey;
  border-radius: 2px;
  background-color: $white;
  cursor: pointer;
  position: relative;
  outline: none;
  transition: background-color 0.3s, border-color ease 0.3s;
  background-color: $primary;
  border-color: $primary;
}

.role_checked::after {
  content: '';
  position: absolute;
  top: 3px; /* Positioning for the tick mark */
  left: 6px;
  width: 5px;
  height: 10px;
  border: solid white; /* White color for the tick */
  border-width: 0 3px 3px 0; /* Create the tick shape */
  transform: rotate(45deg); /* Rotate to form the tick */
  border-radius: 1px;
}
.role_unchecked{
  width: 20px;
  height: 20px;
  background: #146ef5;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
}
.auth_screen {
  display: flex;
  height: 100vh;
  width: 100%;
}
.auth_image {
  flex: 35%;
  background-image: url("../../../assets/images/authImage.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 100vh;
  background: #e7f0fe;
  position: relative;
  .auth_image_icon {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: end;
    padding-bottom: 30px;
    padding-right: 20px;
  }
  .auth_image_logo_wrap {
    position: absolute;
    top: 25px;
    left: 25px;
    .auth_image_content {
      width: 230px;
      font-size: 22;
      font-weight: 400;
      color: #1b242c;
      margin-top: 20px;
    }
  }
}
.auth_content {
  background-color: #fff;
  flex: 65%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.auth_child {
  flex: 1;
  max-width: 490px;
  min-width: 0;
  width: 100%;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .auth_image {
    display: none;
  }
  .auth_content {
    flex: 100%;
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .auth_image_icon {
    scale: 0.8;
    transform-origin: bottom;
  }
}
@media (min-width: 1300px) and (max-width: 1500px) {
  .auth_image_icon {
    scale: 0.9;
    transform-origin: bottom;
  }
}

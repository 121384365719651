@import "../../../../../assets/styles/variables.scss";

.transStyle {
  display: flex;
}
.fundedViewleftWrap {
  width: 35%;
  border: solid 1px $dark6;
  margin-top: 2rem;
  padding: 10px;
}
.fundedViewleftWrapEdit {
  width: 45%;
  border: solid 1px $dark6;
  margin-top: 2rem;
  padding: 10px;
}
.label {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  font-family: $font-Instrument;
  color: $dark10;
  width: 40%;
}
.value {
  font-size: 14px !important;
  line-height: 16px;
  font-weight: 500 !important;
  font-family: $font-Instrument;
  color: $dark14;
  width: 60;
}
.valueWrap {
  border-top: solid 1px $dark6;
  border-bottom: solid 1px $dark6;
  display: flex;
  align-items: center;
}
.valueWrapBotton {
  // border-top: solid 1px $dark6;
  border-bottom: solid 1px $dark6;
  display: flex;
  align-items: center;
}
.buttonStyle {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}
.bothsidewrap {
  display: flex;
  gap: 20px;
}
.fundedViewRightWrap {
  margin-top: 30px;
  width: 63%;
  height: 600px;
}
.iframeStyle {
  width: 100%;
  height: 100%;
}
.imagesNameStyle {
  border: 1px solid $side_grey;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  border-radius: 4px;
  cursor: pointer;
}
.closeIcon {
  position: absolute;
  top: 15%;
  right: 4%;
  cursor: pointer;
}
.imagesName {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 90%;
}
.uploadImageWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.DateRangeWrap {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
}
.excelBoadyWrap{
  padding: 10px 0;
  overflow: auto;
}
.headerStyleCell{
  padding: 5px;
}
.columnCellStyle{
  padding: 5px;
}
.InterestDetailsWrap{
  padding-bottom: 100px;
}
.documentsWrap{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  justify-content: flex-start;
}
.documentsButtonWrap{
  display: flex;
  gap: 9px;
  margin-bottom: 10px;
}

// .border{
//   border: 1px solid #DEE3E7;
// }
.dashboard-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 1rem;

  .widgets,
  .quick-links {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
  }

  .widgets{
  svg,
  path {
    fill: #111112;
    width: 24px;
    height: 24px;
  }
}

  .quick-links {
    margin: 1.5rem 0;
    gap: 1rem;
    align-items: start;
    justify-content: center;

    svg,
    path {
    fill: #111112;

      width: 20px;
      height: 20px;
    }
  }

  .quick-link {
    display: flex;
    align-items: center;

    flex-direction: column;
    justify-content: space-between;
    min-width: 100px;
    max-width: 100px;

    .description {
      font-weight: 600;
      text-align: center;
      margin-top: 0.5rem;
      cursor: pointer;
    }

    .description:hover {
      animation: zoom 0.4s ease-in-out;
    }

    @keyframes zoom {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.2); /* Slightly enlarge */
      }
      100% {
        transform: scale(1);
      }
    }
  }

  .common {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
    padding: 1rem;
  }

  .main {
    h1 {
      line-height: normal;
    }
    width: 100%;
    .left,
    .right {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    display: flex;
    gap: 24px;
  }

  /* Style for the first child of .main */
  .left {
    width: 70%;
  }

  /* Style for the last child of .main */
  .right {
    width: 30%;
  }
}

@media (max-width: 1550px) {
 .quick-links{
  gap: 2px !important;
 }

 .widgets{
   .info-card{
    width: 222px;
   }
 }
}

@import '../../../assets/styles/variables.scss';
.sidebar {
  width: 300px !important;

  display: flex;
  flex-direction: column;
  transition: width 0.6s ease;
  background-color: $dark;
  height: 100vh;
  text-decoration: none; // Remove underlines from the sidebar
  position: relative;

  .header {
    padding: 1.5rem 0 1.5rem 1.5rem;
    align-items: center;
    display: flex;
    cursor: pointer;
    .open-header-img {
      height: 23px;
    }

    .closed-header-img {
      height: 21px;
    }

    .drawer-div {
      background-color: $primary;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 12px;
      cursor: pointer;
      border-radius: 4px 0 0 4px;
      z-index: 3;

      position: absolute;
      right: 0;

      svg {
        fill: $white;
      }

      &.closed {
        scale: -1;
        right: -12px;
      }
    }
  }

  .active-side {
    color: $white !important;
    background: $primary;
    border-radius: 6px;
  }
  .sidebar-link {
    span {
      color: $side_grey;
      height: 22.5px;
      font-weight: 400;
    }
    path {
      fill: $side_grey;
    }
  }

  .sub-menu .sidebar-link {
    span {
      font-size: 13px ;
      height: 20px;
    }
  }

  .ul {
    list-style-type: none;
    padding: 0;
    margin: 0 0 0 12px;
    overflow-y: auto;
    overflow-x: hidden;

    padding-right: 12px;
    min-height: calc(100vh - 144px);

    li {
      list-style: none;
      margin-bottom: 4px;
      padding: 10px;
      min-height: 22.5px;

      a {
        text-decoration: none;
        color: $dark; // Set the text color for links
        transition: color 0.3s ease; // Smooth transition for color change
        font-size: 15px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;

        i {
          display: flex;
          width: 18px;
          margin-right: 8px;
        }
        &:hover {
          color: $white;
          path {
            fill: $white;
            transition: 0.3s ease;
          }
        }
      }
      .sidebar-active-link {
        &:hover {
          path {
            fill: $white;
          }
        }
      }
    }
  }

  .ul::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar */
  }

  .ul::-webkit-scrollbar-thumb {
    background: #c0c4c9; /* Color of the scrollbar thumb */
    border-radius: 6px; /* Roundness of the scrollbar thumb */
  }

  .ul::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scrollbar thumb when hovered */
  }

  &.closed {
    width: 4rem !important;
  }
}

.closed {
  .header {
    padding: 1.3rem;
  }

  .profile {
    background-color: transparent;
    transition: ease 0.3s;
    padding: 16px 0;
  }
}

.sidebar-active-link {
  span {
    font-weight: 500 !important;
    color: $white !important;
    transition: 0.3s ease;
  }
  path {
    fill: $white !important;
    transition: 0.2s ease;
  }
}

.grey-fill-saved {
  fill: #4e5660;
}

.profile {
  height: 74px;
  background-color: #464c61;
  margin: 12px 16px;
  border-radius: 8px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 6px 16px;
  cursor: pointer;
  font-weight: 500;
  .profile-span {
    margin-top: 2px;
    color: $side_grey;
  }

  svg {
    fill: $side_grey;
  }
}
